import { defineStore } from 'pinia'
import axios from 'axios'
import { UserRole } from '../pages/users/types'

export const useUserStore = defineStore('user', {
  state: () => {
    const originalUser = localStorage.getItem('originalUser')
    // console.log('Estado original:', originalUser)
    return {
      // Informações de contato
      id: Number(localStorage.getItem('id')) || 0, // Adicione o campo id
      email: localStorage.getItem('email') || '',
      username: localStorage.getItem('username') || '',
      fullname: localStorage.getItem('fullname') || '',
      firstName: localStorage.getItem('firstName') || '',
      lastName: localStorage.getItem('lastName') || '',

      cpf: localStorage.getItem('cpf') || '', // Novo campo CPF
      whatsapp: localStorage.getItem('whatsapp') || '', // Novo campo WhatsApp
      address_line1: localStorage.getItem('address_line1') || '', // Novo campo Endereço 1
      address_line2: localStorage.getItem('address_line2') || '', // Novo campo Endereço 2
      city: localStorage.getItem('city') || '', // Novo campo Cidade
      state: localStorage.getItem('state') || '', // Novo campo Estado
      postal_code: localStorage.getItem('postal_code') || '', // Novo campo Código Postal

      // Detalhes do perfil
      pfp: localStorage.getItem('pfp') || '', // Profile picture
      avatar: localStorage.getItem('avatar') || '',
      country: localStorage.getItem('country') || '',
      notes: localStorage.getItem('notes') || '',

      // Configurações de segurança
      is2FAEnabled: localStorage.getItem('is2FAEnabled') === 'true',

      // Status do usuário
      memberSince: localStorage.getItem('memberSince') || '',
      active: localStorage.getItem('active') === 'true',
      starred: localStorage.getItem('starred') === 'true',
      hasReport: localStorage.getItem('hasReport') === 'true',
      status: localStorage.getItem('status') || '',
      checked: localStorage.getItem('checked') === 'true',

      // Informações adicionais
      trend: localStorage.getItem('trend') || '',
      color: localStorage.getItem('color') || '',
      graph: localStorage.getItem('graph') || '',
      graphColor: localStorage.getItem('graphColor') || '',
      role: (localStorage.getItem('role') as UserRole) || 'Usuário', // Adicione o campo role
      projects: JSON.parse(localStorage.getItem('projects') || '[]'), // Adicione o campo projects

      // Assinaturas
      plan_id: localStorage.getItem('plan_id') || '',
      end_date: localStorage.getItem('end_date') || '',
      is_active: localStorage.getItem('is_active') === 'true',

      isLoggedIn: !!localStorage.getItem('token'), // Verificar se o token está presente
      keepLoggedIn: localStorage.getItem('keepLoggedIn') === 'true', // Adicionar estado para "Keep me signed in"

      originalUser: originalUser ? JSON.parse(originalUser) : null,
      isLoggedInAsUser: localStorage.getItem('isLoggedInAsUser') === 'true',
    }
  },

  actions: {
    async loginAsUser(username: string) {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_BASE_URL}/api/login-as-user/`,
          { username },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )

        if (response.status === 200) {
          // Salva o estado original do admin antes de trocar para o novo usuário
          if (!this.isLoggedInAsUser) {
            this.originalUser = { ...this.$state } // Salva o estado original do admin
            localStorage.setItem('originalUser', JSON.stringify(this.originalUser))
          }
          const userData = response.data // Captura os dados do usuário retornados
          this.firstName = userData.firstName || ''
          this.username = userData.username || ''
          this.email = userData.email || ''
          this.cpf = userData.cpf || ''
          this.whatsapp = userData.whatsapp || ''
          this.address_line1 = userData.address_line1 || ''
          this.address_line2 = userData.address_line2 || ''
          this.city = userData.city || ''
          this.state = userData.state || ''
          this.postal_code = userData.postal_code || ''
          this.fullname = userData.fullname || ''
          this.memberSince = userData.memberSince || ''
          this.pfp = userData.pfp || ''
          this.is2FAEnabled = userData.is2FAEnabled || false
          this.avatar = userData.avatar || ''
          this.country = userData.country || ''
          this.notes = userData.notes || ''
          this.active = userData.active || false
          this.starred = userData.starred || false
          this.hasReport = userData.hasReport || false
          this.status = userData.status || ''
          this.checked = userData.checked || false
          this.trend = userData.trend || ''
          this.color = userData.color || ''
          this.graph = userData.graph || ''
          this.graphColor = userData.graphColor || ''
          this.role = userData.role || 'Usuário'
          this.isLoggedInAsUser = true
          this.isLoggedIn = true
          this.keepLoggedIn = true

          // Atualiza os valores no localStorage
          localStorage.setItem('firstName', userData.firstName || '')
          localStorage.setItem('username', userData.username || '')
          localStorage.setItem('email', userData.email || '')
          localStorage.setItem('fullname', userData.fullname || '')
          localStorage.setItem('cpf', userData.cpf || '')
          localStorage.setItem('whatsapp', userData.whatsapp || '')
          localStorage.setItem('address_line1', userData.address_line1 || '')
          localStorage.setItem('address_line2', userData.address_line2 || '')
          localStorage.setItem('city', userData.city || '')
          localStorage.setItem('state', userData.state || '')
          localStorage.setItem('postal_code', userData.postal_code || '')
          localStorage.setItem('memberSince', userData.memberSince || '')
          localStorage.setItem('pfp', userData.pfp || '')
          localStorage.setItem('is2FAEnabled', (userData.is2FAEnabled || false).toString())
          localStorage.setItem('avatar', userData.avatar || '')
          localStorage.setItem('country', userData.country || '')
          localStorage.setItem('notes', userData.notes || '')
          localStorage.setItem('active', (userData.active || false).toString())
          localStorage.setItem('starred', (userData.starred || false).toString())
          localStorage.setItem('hasReport', (userData.hasReport || false).toString())
          localStorage.setItem('status', userData.status || '')
          localStorage.setItem('checked', (userData.checked || false).toString())
          localStorage.setItem('trend', userData.trend || '')
          localStorage.setItem('color', userData.color || '')
          localStorage.setItem('graph', userData.graph || '')
          localStorage.setItem('graphColor', userData.graphColor || '')
          localStorage.setItem('role', userData.role || 'Usuário')
          localStorage.setItem('isLoggedInAsUser', 'true')
          localStorage.setItem('keepLoggedIn', 'true')

          localStorage.setItem('token', response.data.access)
          localStorage.setItem('refreshToken', response.data.refresh)

          window.location.reload()
        }
      } catch (error) {
        console.error('Erro ao tentar logar como usuário:', error)
      }
    },

    async revertToAdmin() {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_API_BASE_URL}/api/revert-to-admin/`,
          { username: this.originalUser.username }, // Envia o username
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`, // Adiciona o token ao cabeçalho
            },
          },
        )

        if (response.status === 200) {
          // Atualiza o estado com os dados retornados do backend
          const userData = response.data // Captura os dados do usuário retornados
          this.firstName = userData.firstName || ''
          this.username = userData.username || ''
          this.email = userData.email || ''
          this.cpf = userData.cpf || ''
          this.whatsapp = userData.whatsapp || ''
          this.address_line1 = userData.address_line1 || ''
          this.address_line2 = userData.address_line2 || ''
          this.city = userData.city || ''
          this.state = userData.state || ''
          this.postal_code = userData.postal_code || ''
          this.fullname = userData.fullname || ''
          this.memberSince = userData.memberSince || ''
          this.pfp = userData.pfp || ''
          this.is2FAEnabled = userData.is2FAEnabled || false
          this.avatar = userData.avatar || ''
          this.country = userData.country || ''
          this.notes = userData.notes || ''
          this.active = userData.active || false
          this.starred = userData.starred || false
          this.hasReport = userData.hasReport || false
          this.status = userData.status || ''
          this.checked = userData.checked || false
          this.trend = userData.trend || ''
          this.color = userData.color || ''
          this.graph = userData.graph || ''
          this.graphColor = userData.graphColor || ''
          this.role = userData.role || 'Usuário'
          this.plan_id = userData.plan_id || ''
          this.end_date = userData.end_date || ''
          this.is_active = userData.is_active || false
          this.isLoggedInAsUser = false
          this.isLoggedIn = true
          this.keepLoggedIn = true

          // Atualiza os valores no localStorage
          localStorage.setItem('firstName', userData.firstName || '')
          localStorage.setItem('username', userData.username || '')
          localStorage.setItem('email', userData.email || '')
          localStorage.setItem('fullname', userData.fullname || '')
          localStorage.setItem('cpf', userData.cpf || '')
          localStorage.setItem('whatsapp', userData.whatsapp || '')
          localStorage.setItem('address_line1', userData.address_line1 || '')
          localStorage.setItem('address_line2', userData.address_line2 || '')
          localStorage.setItem('city', userData.city || '')
          localStorage.setItem('state', userData.state || '')
          localStorage.setItem('postal_code', userData.postal_code || '')
          localStorage.setItem('memberSince', userData.memberSince || '')
          localStorage.setItem('pfp', userData.pfp || '')
          localStorage.setItem('is2FAEnabled', (userData.is2FAEnabled || false).toString())
          localStorage.setItem('avatar', userData.avatar || '')
          localStorage.setItem('country', userData.country || '')
          localStorage.setItem('notes', userData.notes || '')
          localStorage.setItem('active', (userData.active || false).toString())
          localStorage.setItem('starred', (userData.starred || false).toString())
          localStorage.setItem('hasReport', (userData.hasReport || false).toString())
          localStorage.setItem('status', userData.status || '')
          localStorage.setItem('checked', (userData.checked || false).toString())
          localStorage.setItem('trend', userData.trend || '')
          localStorage.setItem('color', userData.color || '')
          localStorage.setItem('graph', userData.graph || '')
          localStorage.setItem('graphColor', userData.graphColor || '')
          localStorage.setItem('role', userData.role || 'Usuário')
          localStorage.setItem('plan_id', userData.plan_id || '')
          localStorage.setItem('end_date', userData.end_date || '')
          localStorage.setItem('is_active', (userData.is_active || false).toString())
          localStorage.setItem('isLoggedInAsUser', 'false')
          localStorage.setItem('keepLoggedIn', 'true')

          localStorage.setItem('token', response.data.access)
          localStorage.setItem('refreshToken', response.data.refresh)

          localStorage.removeItem('originalUser')
          window.location.reload()
        }
      } catch (error) {
        console.error('Erro ao tentar reverter para admin:', error)
      }
    },
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
      localStorage.setItem('is2FAEnabled', this.is2FAEnabled.toString())
    },

    changeFirstName(firstName: string) {
      this.firstName = firstName
      localStorage.setItem('firstName', firstName)
    },
    changeFullName(newFullName: string) {
      this.fullname = newFullName
      localStorage.setItem('fullname', newFullName)
    },

    changeUserName(username: string) {
      this.username = username
      localStorage.setItem('username', username)
    },
    changeCpf(cpf: string) {
      this.cpf = cpf
      localStorage.setItem('cpf', cpf)
    },
    changeWhatsapp(whatsapp: string) {
      this.whatsapp = whatsapp
      localStorage.setItem('whatsapp', whatsapp) // Adicionando método para atualizar WhatsApp
    },

    login(
      userData: {
        firstName: string
        username: string
        email: string
        fullname: string
        cpf: string
        whatsapp: string
        address_line1: string
        address_line2: string
        city: string
        state: string
        postal_code: string
        memberSince: string
        pfp: string
        is2FAEnabled: boolean
        avatar: string
        country: string
        notes: string
        active: boolean
        starred: boolean
        hasReport: boolean
        status: string
        checked: boolean
        trend: string
        color: string
        graph: string
        graphColor: string
        role: UserRole
        plan_id: string
        end_date: string
        is_active: boolean
      },
      keepLoggedIn: boolean,
    ) {
      this.firstName = userData.firstName
      this.username = userData.username
      this.email = userData.email
      this.fullname = userData.fullname
      this.cpf = userData.cpf
      this.whatsapp = userData.whatsapp
      this.address_line1 = userData.address_line1
      this.address_line2 = userData.address_line2
      this.city = userData.city
      this.state = userData.state
      this.postal_code = userData.postal_code
      this.memberSince = userData.memberSince
      this.pfp = userData.pfp
      this.is2FAEnabled = userData.is2FAEnabled
      this.avatar = userData.avatar
      this.country = userData.country
      this.notes = userData.notes
      this.active = userData.active
      this.starred = userData.starred
      this.hasReport = userData.hasReport
      this.status = userData.status
      this.checked = userData.checked
      this.trend = userData.trend
      this.color = userData.color
      this.graph = userData.graph
      this.graphColor = userData.graphColor
      this.role = userData.role
      this.plan_id = userData.plan_id || ''
      this.end_date = userData.end_date || ''
      this.is_active = userData.is_active || false
      this.isLoggedIn = true
      this.keepLoggedIn = keepLoggedIn

      localStorage.setItem('firstName', userData.firstName)
      localStorage.setItem('username', userData.username)
      localStorage.setItem('email', userData.email)
      localStorage.setItem('fullname', userData.fullname)
      localStorage.setItem('cpf', userData.cpf)
      localStorage.setItem('whatsapp', userData.whatsapp)
      localStorage.setItem('address_line1', userData.address_line1)
      localStorage.setItem('address_line2', userData.address_line2)
      localStorage.setItem('city', userData.city)
      localStorage.setItem('state', userData.state)
      localStorage.setItem('postal_code', userData.postal_code)
      localStorage.setItem('memberSince', userData.memberSince)
      localStorage.setItem('pfp', userData.pfp)
      localStorage.setItem('is2FAEnabled', userData.is2FAEnabled.toString())
      localStorage.setItem('avatar', userData.avatar)
      localStorage.setItem('country', userData.country)
      localStorage.setItem('notes', userData.notes)
      localStorage.setItem('active', userData.active.toString())
      localStorage.setItem('starred', userData.starred.toString())
      localStorage.setItem('hasReport', userData.hasReport.toString())
      localStorage.setItem('status', userData.status)
      localStorage.setItem('checked', userData.checked.toString())
      localStorage.setItem('trend', userData.trend)
      localStorage.setItem('color', userData.color)
      localStorage.setItem('graph', userData.graph)
      localStorage.setItem('graphColor', userData.graphColor)
      localStorage.setItem('role', userData.role)
      localStorage.setItem('plan_id', this.plan_id)
      localStorage.setItem('end_date', this.end_date)
      localStorage.setItem('is_active', this.is_active.toString())
      localStorage.setItem('keepLoggedIn', keepLoggedIn.toString())

      if (!this.isLoggedInAsUser) {
        this.originalUser = null
        localStorage.removeItem('originalUser')
      }
      console.log('User logged in:', userData)
    },

    logout() {
      this.firstName = ''
      this.username = ''
      this.email = ''
      this.cpf = ''
      this.whatsapp = ''
      this.address_line1 = ''
      this.address_line2 = ''
      this.city = ''
      this.state = ''
      this.postal_code = ''
      this.memberSince = ''
      this.pfp = ''
      this.is2FAEnabled = false
      this.avatar = ''
      this.country = ''
      this.notes = ''
      this.active = false
      this.starred = false
      this.hasReport = false
      this.status = ''
      this.checked = false
      this.trend = ''
      this.color = ''
      this.graph = ''
      this.graphColor = ''
      this.role = 'Usuário' as UserRole
      this.plan_id = ''
      this.end_date = ''
      this.is_active = false
      this.isLoggedIn = false
      this.keepLoggedIn = false

      localStorage.removeItem('token')
      localStorage.removeItem('firstName')
      localStorage.removeItem('username')
      localStorage.removeItem('email')
      localStorage.removeItem('cpf')
      localStorage.removeItem('whatsapp')
      localStorage.removeItem('address_line1')
      localStorage.removeItem('address_line2')
      localStorage.removeItem('city')
      localStorage.removeItem('state')
      localStorage.removeItem('postal_code')
      localStorage.removeItem('memberSince')
      localStorage.removeItem('pfp')
      localStorage.removeItem('is2FAEnabled')
      localStorage.removeItem('avatar')
      localStorage.removeItem('country')
      localStorage.removeItem('notes')
      localStorage.removeItem('active')
      localStorage.removeItem('starred')
      localStorage.removeItem('hasReport')
      localStorage.removeItem('status')
      localStorage.removeItem('checked')
      localStorage.removeItem('trend')
      localStorage.removeItem('color')
      localStorage.removeItem('graph')
      localStorage.removeItem('graphColor')
      localStorage.removeItem('role')
      localStorage.removeItem('plan_id')
      localStorage.removeItem('end_date')
      localStorage.removeItem('is_active')
      localStorage.removeItem('keepLoggedIn')

      this.originalUser = null
      localStorage.removeItem('originalUser')
      this.isLoggedInAsUser = false
      localStorage.removeItem('isLoggedInAsUser')

      console.log('User logged out')
    },

    async refreshToken() {
      try {
        const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/token/refresh/`, {
          refresh: localStorage.getItem('refreshToken'),
        })
        localStorage.setItem('token', response.data.access)
        console.log('Token refreshed:', response.data.access)
      } catch (error) {
        console.error('Error refreshing token:', error)
        this.logout()
      }
    },

    async checkAuth() {
      const token = localStorage.getItem('token')
      if (token) {
        const tokenExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000
        const now = new Date().getTime()
        // console.log('Token expiration:', new Date(tokenExpiration))
        // console.log('Current time:', new Date(now))
        if (now > tokenExpiration) {
          // console.log('Token expired, refreshing...')
          if (this.keepLoggedIn) {
            await this.refreshToken()
          } else {
            this.logout()
          }
        } else {
          // console.log('Token is still valid')
        }
      } else {
        // console.log('No token found, logging out')
        this.logout()
      }
    },

    hasRole(roles: string[]) {
      return roles.includes(this.role)
    },
  },
})
